(function () {
  "use strict";

  $(document).ready(function () {
    $(".slider-hero").slick({
      arrows: false,
      dots: true,
      appendDots: $(".wrap-slick-dots"),
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      autoplay: true,
      autoplaySpeed: 1500,
      pauseOnHover: true,
      // fade: true,
      cssEase: "ease-in-out",
    });

    $(".slider-partners").slick({
      arrows: false,
      dots: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      mobileFirst: true,
      autoplay: true,
      autoplaySpeed: 1000,
      responsive: [
        {
          breakpoint: 656,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 7,
          },
        },
      ],
    });

    $(".slider-products-categories").slick({
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 6,
          },
        },
      ],
    });

    $(".slider-products").slick({
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
      ],
    });

    $(".slider-brands").slick({
      arrows: true,
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }); // close document get ready
})(jQuery);
