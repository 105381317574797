(function () {
  "use strict";

  $(document).ready(function () {
    // Navbar fixed scroll up
    var previousScroll = 0;
    $(window).scroll(function () {
      var currentScroll = $(this).scrollTop();

      // Verifica se l'utente sta scorrendo verso l'alto
      if (currentScroll < previousScroll) {
        // Calcola la percentuale di scroll verso l'alto
        var scrollPercentage = (currentScroll / previousScroll) * 100;

        // Mostra la navbar fissa se la percentuale di scroll verso l'alto è maggiore di zero
        if (scrollPercentage > 0) {
          $("#header").addClass("fixed");
        } else {
          // Rimuovi la classe "fixed" se la percentuale di scroll verso l'alto è zero
          $("#header").removeClass("fixed");
        }
      } else {
        // Nascondi la navbar fissa se l'utente sta scorrendo verso il basso
        $("#header").removeClass("fixed");
      }

      previousScroll = currentScroll;
    });

    // Mobile menu
    $(".menu-toggle").on("click", function () {
      $(".menu-mobile").addClass("menu-mobile-open");
    });
    $(".menu-mobile-close").on("click", function () {
      $(".menu-mobile").removeClass("menu-mobile-open");
    });
    // Accordion mobile menu
    $(".menu_mobile-nav > li").on("click", function () {
      $(this).children(".sub-menu").slideToggle();
    });
  }); // close document get ready
})(jQuery);
